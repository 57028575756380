import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      constants: {
        branches: {
          found: "Found",
          lost: "Lost",
        },
        categories: {
          people: "People",
          auto: "Auto",
          electronics: "Electronics",
          documents: "Documents",
          keys: "Keys",
          bags: "Bags",
          animals: "Animals",
          clothes: "Clothes, Footwear, Accessories",
          childrenThings: "Children's Things",
          other: "Other",
        },
        roles: {
          vanillaalbino: "vanillaalbino",
          administrator: "Administrator",
          moderator: "Moderator",
          user: "User",
        },
      },
      header: {
        loginSignup: "Login / Sign Up",
        postAnnouncement: "Post an Announcement",
        profileSettings: "Profile Settings",
        adminPanel: "Admin panel",
        logOut: "Log Out",
        messages: "Messages",
        roleManager: "Role manager",
        moderation: "Moderation",
        profile: "Profile",
        security: "Security and login",
      },
      breadcrumbs: {
        home: "Home",
        search: "Search",
      },
      filter: {
        found: "Found",
        lost: "Lost",
        allCategories: "All categories",
        searchAnnouncements: "Search announcements",
        location: "Location",
        search: "Search",
      },
      footer: {
        TIN: "TIN: 780729468799",
        privacy: "Privacy",
        terms: "Terms",
      },
      authForm: {
        agreeWithTerms: "Please agree to the terms of use and privacy policy.",
        correctEmail: "Please enter a valid email address.",
        passwordLength: "The password must be at least 8 characters long.",
        activationLinkResent:
          "The account activation link has been resent. Please check your email.",
        checkForActivation: "Please check your email to activate your account.",
        error: "An unexpected error has occurred. Please try again.",
        accountNotActivated:
          "Account not activated. Please check your email for activation instructions.",
        registration: "Sign up",
        logIn: "Log in",
        email: "Email",
        password: "Password",
        forgotPassword: "Forgot your password?",
        toRegister: "Sign up",
        toLogIn: "Log in",
        IAmAgree: "I agree with",
        termsOfUse: "terms of use",
        and: "and",
        privacy: "privacy",
        ofFoundy: "of Foundy",
        or: "Or",
        vk: "VK",
        alreadyHasAccount: "Already have an account?",
        doesNotHaveAccount: "Don't have an account yet?",
      },
      deleteAccountModal: {
        deletedSuccessfully: "The account has been successfully deleted.",
        title: "Are you sure you want to delete your account?",
        text: "You will lose access to all content with no possibility of recovery.",
        buttonText: "Confirm deletion",
      },
      deleteAnnouncementModal: {
        deletedSuccessfully: "The announcement has been successfully deleted.",
        error: "An error occurred while deleting the announcement: ",
        title: "Are you sure you want to delete the announcement?",
        buttonText: "Confirm deletion",
      },
      passwordResetModal: {
        enterPassword: "Please enter your password.",
        resetSuccessfully:
          "Password successfully reset. Please log in with your new password.",
        error: "Error resetting password: ",
        title: "Password reset",
        text: "Enter your new password below:",
        newPassword: "New password",
        buttonText: "Reset password",
      },
      passwordResetRequestModal: {
        enterCorrectEmail: "Please enter a valid email address.",
        sentInstructions:
          "Password reset instructions have been sent to your email.",
        error: "Error resetting password:",
        title: "Password recovery",
        text: "Please enter the email address associated with your account. We will send you a link to reset your password.",
        enterEmail: "Enter your email address",
        buttonText: "Reset password",
      },
      home: {
        mainHeadline: "Search for people, things and pets",
        slogan: "The things we lose have a way of coming back to us",
        foundGroupTitle: "Found",
        lostGroupTitle: "Lost",
        viewAll: "View all",
        homeTitle:
          "Welcome to Foundy - A Platform for Finding People, Items, and Animals",
        homeDescription:
          "Foundy: Announcements in the categories of Lost and Found from around the world",
      },
      searchPage: {
        announcementsForQuery: "Announcements for query ",
        inCategory: "in category",
        in: "in",
        sort: "Sort",
        sortByPrice: "Sort by price",
        notFoundMessage: "Nothing found",
        notFoundSubMessage: "Try changing the search parameters",
      },

      announcement: {
        loading: "Loading...",
        announcementNotFound: "Announcement not found",
        tryAnotherAnnouncement: "Try selecting another announcement",
        errorOccurred: "An error occurred",
        tryAgainLater: "Please try again later",
        loadingAnnouncementData: "Loading announcement data...",
        location: "Location",
        description: "Description",
        sendMessage: "Send Message",
      },
      newAnnouncement: {
        comission: "Foundy comission",
        required: "Branch, category, and title are required.",
        edited: "Announcement successfully edited",
        added: "Announcement successfully added",
        title: "New announcement",
        message:
          "Please provide accurate and up-to-date information. Do not specify exact location.",
        announcementTitle: "Announcement Title",
        inputInfo: "Use keywords",
        descriptionTitle: "Announcement Description",
        descriptionInfo: "Briefly describe the announcement",
        submitBtn: "Publish",
        categoryPlaceholder: "Choose category",
        categoryTitle: "Category",
        pricePlaceholder: "Enter amount",
        priceTitle: "Reward amount for finder",
        youCannotEditPrice: "You cannot change the price while editing.",
        commissionMessage: "Foundy commission",
        locationPlaceholder: "Start typing location",
        locationTitle: "Location",
        locationError: "Do not specify exact location",
        upTo8Files: "You can only upload up to 8 files.",
        tooLarge:
          "Some files are too large. Please upload files smaller than 4MB.",
        groupHeader: "Photos",
        text: "No more than 8",
        formTitle: "Enter your email",
        formText: "We will send you a receipt",
        Email: "Email",
        formSubmit: "Submit",
      },
      profile: {
        loading: "Loading...",
        myAnnouncements: "My announcements",
        announcements: "Announcements",
        lost: "Lost",
        found: "Found",
        notFoundMessage: "There are no announcements in this branch",
      },
      chat: {
        title: "Messages",
        noMessages: "Here will be displayed the history of correspondence",
        chooseChat: "Select a chat",
        noChats: "There are no chats yet",
        onePhoto: "1 photo",
        twoToFivePhotos: "photos",
        fiveAndMorePhotos: "photos",
        oneMessage: "message",
        twoToFiveMessages: "messages",
        fiveAndMoreMessages: "messages",
        deleteSelected: "Delete selected",
        sendMessage: "Write a message",
      },
      adminPanel: {
        roleManager: "Role manager",
        moderation: "Moderation",
        delete: "Delete",
        couldNotUnban: "Failed to unban the user",
        unbanned: "User has been successfully unbanned",
        incorrectID: "Please enter the correct user ID",
        notFound: "The user with the specified ID was not found",
        banDataError: "Error retrieving user ban data. Please try again.",
        unableToBan: "You are unable to ban this user",
        alreadyBanned: "This user is already banned",
        successfullyBanned: "The user has been successfully banned",
        userID: "User ID",
        reason: "Reason",
        endDate: "End date",
        createdBy: "Created by",
        actions: "Actions",
        search: "Search",
        userName: "User name",
        ban: "Ban",
        role: "Role",
        assignedBy: "Assigned by",
        assignedAt: "Assigned at",
        addRole: "Add role",
        save: "Save",
      },
      editProfile: {
        profile: "Profile",
        security: "Security and login",
        fileTooLarge:
          "File is too large. Please upload a file smaller than 4MB.",
        name: "Name",
        enterName: "Enter your name",
        inputInfo: "Your name will be displayed on your page",
        deleteAccount: "Delete account",
        save: "Save",
        passwordChanged: "Password successfully changed!",
        errorPasswordChanged: "An error occurred while changing the password",
        currentPassword: "Current password",
        forgotPassword: "Forgot your password?",
        newPassword: "New password",
        confirmPassword: "Confirm new password",
      },
      privacy: {
        title: "Privacy Policy of Foundy",
        version: "Version 1.1",
        updateDate: "Last Updated: September 10, 2024",
        introduction: {
          h: "1. Introduction",
          p1: "Welcome to Foundy! We respect your privacy and are committed to protecting your personal information. This Privacy Policy describes what data we collect, how we use and protect it. By using our application, you agree to the terms of this Privacy Policy.",
        },
        infoCollection: {
          h: "2. Information Collection",
          p1: "2.1. We may collect the following data:",
          li1: "Personal information: name, email, phone number, address, and other contact information.",
          li2: "Account data: login, password, and other registration information.",
          li3: "Payment information: credit card details and other transaction-related information.",
          li4: "Technical data: IP address, browser type, operating system, URLs visited, and other technical information.",
          li5: "Information from social network accounts: when you log in via VKontakte (VK) or Google, we may collect and store information available through your profiles on these social networks, such as name, email, and other data permitted by you.",
          p2: "2.2. We collect information directly from you during registration, app usage, and automatically through cookies and other tracking technologies.",
        },
        infoUsage: {
          h: "3. Information Usage",
          p1: "3.1. We use the collected information to:",
          li1: "Ensure the functionality and operation of the application.",
          li2: "Process your requests and provide services.",
          li3: "Improve and personalize your app experience.",
          li4: "Process payments for paid services.",
          li5: "Ensure security and prevent fraud.",
        },
        thirdParties: {
          h: "4. Sharing Information with Third Parties",
          p1: "4.1. We do not sell, transfer, or disclose your personal information to third parties except when necessary to provide services or as required by law.",
          p2: "4.2. When using social network authentication features like VKontakte (VK) and Google, we may share information necessary for authentication and operation of these features.",
          p3: "4.3. By submitting an advertisement on our website, you consent to the potential publication of your advertisement in our official Foundy social media communities, such as VKontakte, for the purpose of attracting new users and increasing the visibility of your advertisement. This may involve sharing your advertisement with these third-party platforms to enhance its reach and exposure.",
        },
        informationSecurity: {
          h: "5. Information Security",
          p1: "5.1. We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure.",
          p2: "5.2. However, we cannot guarantee absolute security of your information and are not liable for unauthorized access, use, or disclosure of your personal information.",
        },
        paidServices: {
          h: "6. Paid Services",
          p1: "6.1. By making a payment, the user agrees to the processing of their personal data necessary for payment processing and service provision.",
          p2: "6.2. The user agrees that transaction data may be disclosed to tax authorities as required by applicable law.",
        },
        yourRights: {
          h: "7. Your Rights",
          p1: "7.1. You have the right to access, correct, or delete your personal information. You can use the appropriate functionality within the application for this purpose.",
        },
        limitationOfLiability: {
          h: "8. Limitation of Liability",
          p1: "8.1. By using our application, you understand and agree that all information you provide is at your own risk.",
          p2: "8.2. We are not liable for any direct or indirect damages arising from the use or inability to use the application, including but not limited to loss of profits, data loss, or any other damages.",
          p3: "8.3. The user agrees that the company will not compensate for any losses or damages, regardless of how they may occur.",
        },
        privacyPolicyChanges: {
          h: "9. Changes to Privacy Policy",
          p1: "9.1. We reserve the right to change this Privacy Policy at any time. All changes take effect immediately upon publication in the application.",
          p2: "9.2. Continued use of the application after changes constitutes acceptance of those changes.",
        },
        terminationOfUse: {
          h: "10. Termination of Use",
          p1: "10.1. We reserve the right to terminate or suspend your access to the application at any time without prior notice.",
          p2: "10.2. You may terminate use of the application at any time by deleting your account.",
        },
        contactInfo: {
          h: "11. Contact Information",
          p1: "If you have any questions or concerns regarding these Terms, please contact us at no-reply@foundy.world.",
        },
        info: "These Terms are effective upon publication and remain in effect until canceled or modified.",
      },
      terms: {
        title: "Terms of Use for Foundy Web Application",
        version: "Version 1.1",
        updateDate: "Last Updated: September 10, 2024",
        introduction: {
          h: "1. Introduction",
          p1: "Welcome to Foundy! We are pleased to welcome you to our platform, designed to facilitate the search for lost and found items, animals, and people. These Terms of Use (hereinafter referred to as the 'Terms') govern your use of our web application. Please read these Terms carefully before using the application. By registering or using the application, you agree to these Terms and confirm your consent to abide by all established rules and norms.",
          p2: "Our application is designed to help users find lost items and pets, as well as reconnect with loved ones and friends. In the case of individuals, the application can be used to search for officially missing persons registered in respective databases or to reestablish contact with people from whom users have lost touch by mutual agreement. We strictly prohibit the use of our application for stalking, fraud, or any other illegal activities.",
          p3: "Users are required to provide accurate and up-to-date information during registration and when posting announcements. We strive to ensure safety and trust within our community, so all users must comply with data protection legislation requirements. Users who violate the Terms of Use may be blocked from accessing the application without prior notice.",
          p4: "Please note that advertisements published on our website may be for demonstration purposes only and may not reflect actual offers or events. We reserve the right to publish sample advertisements to demonstrate the functionality and capabilities of the site. Users should be aware that such advertisements are intended solely for demonstration purposes and may not be accurate or current.",
          p5: "By using our application, you agree that you understand and accept the risks associated with publicly posting information. We do not assume responsibility for the accuracy and content of announcements posted by users; however, we reserve the right to remove any content.",
          p6: "By registering or continuing to use the application after changes to these Terms have been made, you confirm your acceptance of these changes. We recommend reviewing the Terms of Use periodically to stay informed about current rules and policies. Thank you for your trust and we wish you successful use of Foundy to achieve your goals.",
        },
        general: {
          h: "2. General Provisions",
          p1: "2.1. These Terms constitute a legally binding agreement between you (hereinafter referred to as 'User') and Foundy, operated by a self-employed entrepreneur registered with the tax authorities of the Russian Federation under TIN 780729468799 (hereinafter referred to as 'Company', 'we', 'us', or 'our').",
          p2: "2.2. By using our application, you confirm that you have reached the age required to enter into legally binding contracts and have the right and authority to do so.",
          p3: "2.3. We reserve the right to change these Terms at any time. All changes take effect immediately upon their publication in the application.",
          p4: "2.4. The Foundy application is intended for posting announcements about missing and found people, items, and animals. In the case of individuals, it may be used to search for officially missing persons or to find friends and loved ones. The application must not be used for stalking, fraud, or other illegal activities. All users posting announcements are fully responsible for the content of their announcements and confirm that they have obtained all necessary consents from the individuals whose data they publish.",
        },
        registartionAndAccount: {
          h: "3. Registration and Account",
          p1: "3.1. To access certain features of the application, you must register by providing accurate and complete information. You are fully responsible for maintaining the confidentiality of your account and password.",
          p2: "3.2. You agree to immediately notify us of any unauthorized use of your account.",
          p3: "3.3. The user must provide current contact information and keep it up to date.",
        },
        userResponsibility: {
          h: "4. User Responsibility",
          p1: "4.1. The user is fully responsible for all content they post, upload, or transmit through our application.",
          p2: "4.2. The user agrees not to post or transmit content that is unlawful, harmful, offensive, defamatory, infringing on the rights of others, or that could lead to civil or criminal liability.",
          p3: "4.3. The user agrees not to use the application for purposes related to fraud, theft, stalking, or other illegal activities.",
          p4: "4.4. The user agrees that all actions performed through their account are considered to be performed by the user themselves.",
        },
        contentModerationAndSafety: {
          h: "5. Content Moderation and Safety",
          p1: "5.1. We reserve the right to moderate and remove content that, in our opinion, violates these Terms or is unlawful.",
          p2: "5.2. We are not responsible for content posted by users, but reserve the right to cooperate with law enforcement authorities if there is suspicion of illegal activity.",
          p3: "5.3. We encourage users to exercise caution and adhere to general safety rules when communicating and interacting with other users.",
        },
        paidServices: {
          h: "6. Paid Services",
          p1: "6.1. Reward to Finder: When posting an announcement in the lost and found section, users can specify a reward amount for the finder. The user agrees to pay 7% of the specified reward amount through the payment system available on the platform. Specifying a reward amount can increase the visibility of the announcement and interest of other users due to sorting by reward size.",
          p2: "6.2. Service Selection: When creating an announcement, the user specifies the amount and currency of the reward for the finder.",
          p3: "6.3. Payment via Payment System: After selecting the service, the user will be prompted to pay through the integrated payment system. The user must follow the on-screen instructions to complete the payment.",
          p4: "6.4. Payment Confirmation: After successfully completing the payment, an electronic receipt will be automatically sent to the user.",
          p5: "6.5. Refund and Liability: The Company is not responsible for failed payment attempts, payment processing delays, or any technical failures occurring on the side of the payment systems. The user is fully responsible for the accuracy of the data provided and the correctness of payment transactions.",
          p6: "6.6. All transactions and reward payments are made directly between users. The Company does not control or regulate the reward payment process. We are not responsible for whether the reward has been paid and how it is done.",
          p7: "6.7. The Company does not provide any guarantees regarding the success of reward payments. Users are responsible for resolving reward payment issues independently, without involving the Company.",
          p8: "6.8. The Company reserves the right to change the terms of providing paid services and the payment process.",
        },
        refund: {
          h: "7. Refund Policy",
          p1: "7.1. All payments made for paid services are non-refundable, except as expressly provided by the legislation of the Russian Federation.",
          p2: "7.2. In case of an erroneous payment, the user must promptly contact customer support to request a refund. The request will be processed within 30 working days upon receipt of all necessary evidence of the error.",
          p3: "7.3. The Company reserves the right to refuse a refund if it is determined that the payment was made correctly and the services were provided in accordance with the terms of the agreement.",
          p4: "7.4. Funds are not eligible for refund if the refund request is made more than 30 days after the erroneous payment was made.",
          p5: "7.5. When issuing a refund, the amount will be reduced by the commission fee retained by the payment system when paying for paid services. The Company does not reimburse these fees, as the payment system acts as an intermediary and retains its fees.",
        },
        limitationOfLiability: {
          h: "8. Limitation of Liability",
          p1: "8.1. The application is provided 'as is', and we make no warranties regarding its functionality, data accuracy, and security.",
          p2: "8.2. We are not liable for any direct or indirect damages resulting from the use or inability to use the application.",
          p3: "8.3. The user agrees that the Company is not liable for any losses or damages incurred by the user or third parties as a result of using the application, including but not limited to lost profits, data loss, or any other losses.",
          p4: "8.4. To the maximum extent permitted by applicable law, we disclaim any liability to you for any claims related to the use of the application.",
          p5: "8.5. The user agrees that the Company will not compensate for any damages or losses, regardless of how they arise.",
        },
        disclaimerAgreement: {
          h: "9. Agreement on Limitation of Liability",
          p1: "9.1. By registering in our application or using it, the user agrees that they are solely responsible for the posting and content of information.",
          p2: "9.2. The Company is not liable for any actions related to the use of information posted by users, including but not limited to actions by fraudsters, thieves, and stalkers.",
        },
        privacyAndDataProtection: {
          h: "10. Confidentiality and Data Protection",
          p1: "10.1. We comply with data protection legislation and undertake to protect your personal information.",
          p2: "10.2. By using our application, you agree to the terms of our Privacy Policy, which is an integral part of these Terms.",
        },
        terminationOfUse: {
          h: "11. Termination of Use",
          p1: "11.1. We reserve the right to terminate or suspend your access to the application at any time without prior notice if you violate these Terms.",
          p2: "11.2. You may terminate the use of the application at any time by deleting your account.",
        },
        lawAndJurisdiction: {
          h: "12. Applicable Law and Jurisdiction",
          p1: "12.1. These Terms are governed by and construed in accordance with the legislation of the Russian Federation.",
          p2: "12.2. All disputes arising from these Terms shall be resolved in the courts at the location of the company's representative.",
        },
        contactInfo: {
          h: "13. Contact Information",
          p1: "If you have any questions or concerns regarding these Terms, please contact us at no-reply@foundy.world.",
        },
        info: "These Terms come into effect upon their publication and remain valid until they are cancelled or amended.",
      },
    },
    ru: {
      constants: {
        branches: {
          found: "Найденные",
          lost: "Потерянные",
        },
        categories: {
          people: "Люди",
          auto: "Авто",
          electronics: "Электроника",
          documents: "Документы",
          keys: "Ключи",
          bags: "Сумки",
          animals: "Животные",
          clothes: "Одежда, обувь, аксессуары",
          childrenThings: "Детские вещи",
          other: "Другое",
        },
        roles: {
          vanillaalbino: "vanillaalbino",
          administrator: "Администратор",
          moderator: "Модератор",
          user: "Пользователь",
        },
      },
      header: {
        loginSignup: "Вход / Регистрация",
        postAnnouncement: "Разместить объявление",
        profileSettings: "Настройки профиля",
        adminPanel: "Панель администратора",
        logOut: "Выйти",
        messages: "Сообщения",
        roleManager: "Управление ролями",
        moderation: "Модерация",
        profile: "Профиль",
        security: "Безопасность и вход",
      },
      breadcrumbs: {
        home: "Главная",
        search: "Поиск",
      },
      filter: {
        found: "Найденные",
        lost: "Потерянные",
        allCategories: "Все категории",
        searchAnnouncements: "Поиск по объявлениям",
        location: "Местоположение",
        search: "Поиск",
      },
      footer: {
        TIN: "ИНН: 780729468799",
        privacy: "Конфиденциальность",
        terms: "Условия",
      },
      authForm: {
        agreeWithTerms:
          "Пожалуйста, согласитесь с условиями использования и политикой конфиденциальности.",
        correctEmail: "Введите корректный адрес электронной почты",
        passwordLength: "Пароль должен быть не менее 8 символов",
        activationLinkResent:
          "Ссылка для активации аккаунта была отправлена повторно. Пожалуйста, проверьте вашу почту.",
        checkForActivation:
          "Пожалуйста, проверьте вашу почту для активации аккаунта.",
        error: "Произошла неожиданная ошибка. Пожалуйста, попробуйте снова.",
        accountNotActivated:
          "Аккаунт не активирован. Проверьте почту для активации аккаунта.",
        registration: "Регистрация",
        logIn: "Вход",
        email: "Email",
        password: "Пароль",
        forgotPassword: "Забыли пароль?",
        toRegister: "Зарегистрироваться",
        toLogIn: "Войти",
        IAmAgree: "Я согласен с",
        termsOfUse: "условиями использования",
        and: "и",
        privacy: "политикой конфиденциальности",
        ofFoundy: "Фаунди",
        or: "Или",
        vk: "ВКонтакте",
        alreadyHasAccount: "Уже есть аккаунт?",
        doesNotHaveAccount: "Еще нет аккаунта?",
      },
      deleteAccountModal: {
        deletedSuccessfully: "Учетная запись успешно удалена",
        title: "Вы точно хотите удалить аккаунт?",
        text: "Вы потеряете доступ ко всему контенту без возможности восстановления.",
        buttonText: "Подтвердить удаление",
      },
      deleteAnnouncementModal: {
        deletedSuccessfully: "Объявление успешно удалено",
        error: "Произошла ошибка при удалении объявления: ",
        title: "Вы точно хотите удалить объявление?",
        buttonText: "Подтвердить удаление",
      },
      passwordResetModal: {
        enterPassword: "Пожалуйста, введите пароль.",
        resetSuccessfully:
          "Пароль успешно сброшен. Пожалуйста, войдите с новым паролем.",
        error: "Ошибка при сбросе пароля: ",
        title: "Сброс пароля",
        text: "Введите ваш новый пароль ниже:",
        newPassword: "Новый пароль",
        buttonText: "Сбросить пароль",
      },
      passwordResetRequestModal: {
        enterCorrectEmail: "Введите корректный адрес электронной почты",
        sentInstructions:
          "Инструкции для сброса пароля отправлены на ваш email.",
        error: "Ошибка при сбросе пароля:",
        title: "Восстановление пароля",
        text: "Пожалуйста, введите адрес электронной почты, связанный с вашей учетной записью. Мы отправим вам ссылку для сброса пароля.",
        enterEmail: "Введите ваш email",
        buttonText: "Сбросить пароль",
      },
      home: {
        mainHeadline: "Поиск людей, вещей и животных",
        slogan: "Все, что мы теряем, обязательно к нам вернется",
        foundGroupTitle: "Найдены",
        lostGroupTitle: "Потеряны",
        viewAll: "Смотреть все",
        homeTitle:
          "Добро пожаловать в Foundy - платформу для поиска людей, вещей и животных",
        homeDescription:
          "Foundy: объявления в категориях потреянные и найденные со всего мира",
      },
      searchPage: {
        announcementsForQuery: "Объявления по запросу ",
        inCategory: "в категории",
        in: "в",
        sort: "Сортировка",
        sortByPrice: "Сортировать по цене",
        notFoundMessage: "Ничего не найдено",
        notFoundSubMessage: "Попробуйте изменить параметры поиска",
      },

      announcement: {
        loading: "Загрузка...",
        announcementNotFound: "Объявление не найдено",
        tryAnotherAnnouncement: "Попробуйте выбрать другое объявление",
        errorOccurred: "Произошла ошибка",
        tryAgainLater: "Пожалуйста, попробуйте еще раз позже",
        loadingAnnouncementData: "Данные об объявлении загружаются...",
        location: "Местоположение",
        description: "Описание",
        sendMessage: "Написать сообщение",
      },
      newAnnouncement: {
        comission: "Комиссия Foundy",
        required: "Ветка, категория и название обязательны",
        edited: "Объявление успешно отредактировано",
        added: "Объявление успешно добавлено",
        title: "Новое объявление",
        message:
          "Пожалуйста, укажите точную и актуальную информацию. Не указывайте точное местоположение.",
        announcementTitle: "Название объявления",
        inputInfo: "Используйте ключевые слова",
        descriptionTitle: "Описание объявления",
        descriptionInfo: "Кратко опишите объявление",
        submitBtn: "Опубликовать",
        categoryPlaceholder: "Выберите категорию",
        categoryTitle: "Категория",
        pricePlaceholder: "Введите сумму",
        priceTitle: "Сумма вознаграждения нашедшему",
        youCannotEditPrice: "Вы не сможете изменить цену при редактировании.",
        commissionMessage: "Комиссия Foundy",
        locationPlaceholder: "Начните вводить название",
        locationTitle: "Место",
        locationError: "Не указывайте точное местоположение",
        upTo8Files: "Вы можете загрузить до 8 файлов.",
        tooLarge:
          "Некоторые файлы слишком большие. Пожалуйста, загрузите файлы до 4MB.",
        groupHeader: "Фото",
        text: "Не более 8",
        formTitle: "Укажите вашу электронную почту",
        formText: "Мы отправим вам чек",
        Email: "Email",
        formSubmit: "Отправить",
      },
      profile: {
        loading: "Загрузка...",
        myAnnouncements: "Мои объявления",
        announcements: "Объявления",
        lost: "Потерянные ",
        found: "Найденные",
        notFoundMessage: "Нет объявлений в этой ветке",
      },
      chat: {
        title: "Сообщения",
        noMessages: "Здесь будет выводиться история переписки",
        chooseChat: "Выберите чат",
        noChats: "Пока нет чатов",
        onePhoto: "1 фотография",
        twoToFivePhotos: "фотографии",
        fiveAndMorePhotos: "фотографий",
        oneMessage: "сообщение",
        twoToFiveMessages: "сообщения",
        fiveAndMoreMessages: "сообщений",
        deleteSelected: "Удалить",
        sendMessage: "Напишите сообщение",
      },
      adminPanel: {
        roleManager: "Управление ролями",
        moderation: "Модерация",
        delete: "Удалить",
        couldNotUnban: "Не удалось разбанить пользователя",
        unbanned: "Пользователь разбанен",
        incorrectID: "Пожалуйста, введите корректный ID пользователя",
        notFound: "Пользователь с таким ID не найден",
        banDataError: "Ошибка при получении банов пользователя",
        unableToBan: "Вы не можете забанить этого пользователя",
        alreadyBanned: "Этот пользователь уже забанен",
        successfullyBanned: "Пользователь забанен",
        userID: "ID пользователя",
        reason: "Причина",
        endDate: "Дата окончания",
        createdBy: "Создано",
        actions: "Действия",
        search: "Поиск",
        userName: "Имя пользователя",
        ban: "Забанить",
        role: "Роль",
        assignedBy: "Кем назначена",
        assignedAt: "Когда назначена",
        addRole: "Добавить роль",
        save: "Сохранить",
      },
      editProfile: {
        profile: "Профиль",
        security: "Безопасность и вход",
        fileTooLarge:
          "Файл слишком большой. Пожалуйста, загрузите файл размером менее 4MB.",
        name: "Имя",
        enterName: "Введите ваше имя",
        inputInfo: "Имя будет показываться на вашей странице",
        deleteAccount: "Удалить аккаунт",
        save: "Сохранить",
        passwordChanged: "Пароль успешно изменен!",
        errorPasswordChanged: "Произошла ошибка при смене пароля",
        currentPassword: "Текущий пароль",
        forgotPassword: "Забыли пароль?",
        newPassword: "Новый пароль",
        confirmPassword: "Подтвердите новый пароль",
      },
      privacy: {
        title: "Политика конфиденциальности Foundy (Фаунди)",
        version: "Версия 1.1",
        updateDate: "Дата последнего обновления: 10 сентября 2024 г.",
        introduction: {
          h: "1. Введение",
          p1: "Добро пожаловать в Foundy (Фаунди)! Мы уважаем вашу конфиденциальность и обязуемся защищать вашу личную информацию. Настоящая Политика конфиденциальности описывает, какие данные мы собираем, как мы их используем и защищаем. Используя наше приложение, вы соглашаетесь с условиями этой Политики конфиденциальности.",
        },
        infoCollection: {
          h: "2. Сбор информации",
          p1: " 2.1. Мы можем собирать следующие данные:",
          li1: "Личная информация: имя, электронная почта, номер телефона, адрес и другая контактная информация.",
          li2: " Данные аккаунта: логин, пароль и другая информация, необходимая для регистрации.",
          li3: "Платежная информация: данные кредитной карты и другая информация для проведения транзакций.",
          li4: "Технические данные: IP-адрес, тип браузера, операционная система, URL-адреса, с которых вы пришли на наш сайт, и другая техническая информация.",
          li5: " Информация из аккаунтов социальных сетей: при авторизации через ВКонтакте (VK) или Google мы можем собирать и сохранять информацию, доступную через ваши профили в этих социальных сетях,такую как имя, электронная почта и другие данные, разрешенные вами для предоставления.",
          p2: " 2.2. Мы собираем информацию непосредственно от вас при регистрации, использовании приложения, а также автоматически через куки и другие технологии отслеживания.",
        },
        infoUsage: {
          h: "3. Использование информации",
          p1: "3.1. Мы используем собранную информацию для:",
          li1: "Обеспечения работы и функциональности приложения.",
          li2: "Обработки ваших запросов и предоставления услуг.",
          li3: "Улучшения и персонализации вашего опыта использования приложения.",
          li4: "Обработки платежей за платные услуги.",
          li5: "Обеспечения безопасности и предотвращения мошенничества.",
        },
        thirdParties: {
          h: "4. Передача информации третьим лицам",
          p1: "4.1. Мы не продаем, не передаем и не раскрываем вашу личную информацию третьим лицам, за исключением случаев, когда это необходимо для предоставления услуг или по требованию закона.",
          p2: "4.2. При использовании функций авторизации через социальные сети, такие как ВКонтакте (VK) и Google, мы можем передавать информацию, необходимую для выполнения аутентификации и обеспечения работы таких функций.",
          p3: "4.3. При размещении объявления на нашем сайте вы соглашаетесь с тем, что ваше объявление может быть опубликовано в официальных сообществах Foundy в социальных сетях, таких как ВКонтакте, с целью привлечения новых пользователей и увеличения видимости вашего объявления. Мы используем такие платформы для продвижения объявлений и улучшения их доступности для широкой аудитории.",
        },
        informationSecurity: {
          h: "5. Безопасность информации",
          p1: "5.1. Мы принимаем разумные меры для защиты вашей личной информации от несанкционированного доступа, использования или раскрытия.",
          p2: "5.2. Однако мы не можем гарантировать абсолютную безопасность вашей информации и не несем ответственности за несанкционированный доступ, использование или раскрытие вашей личной информации.",
        },
        paidServices: {
          h: "6. Платные услуги",
          p1: "6.1. Пользователь, производящий оплату, соглашается с условиями обработки своих персональных данных, необходимых для проведения платежа и предоставления услуг.",
          p2: "6.2. Пользователь соглашается, что все данные о совершенных транзакциях могут быть переданы в налоговые органы в соответствии с применимым законодательством.",
        },
        yourRights: {
          h: "7. Ваши права",
          p1: "7.1. Вы имеете право на доступ к своей личной информации, ее исправление или удаление. Для этого вы можете воспользоваться соответствующим функционалом приложения.",
        },
        limitationOfLiability: {
          h: "8. Ограничение ответственности",
          p1: " 8.1. Используя наше приложение, вы понимаете и соглашаетесь с тем, что вся информация, которую вы предоставляете, предоставляется на ваш страх и риск.",
          p2: "8.2. Мы не несем ответственности за любой ущерб, прямой или косвенный, возникший в результате использования или невозможности использования приложения, включая, но не ограничиваясь, убытки от упущенной выгоды, потерю данных или любого другого ущерба.",
          p3: "8.3. Пользователь соглашается, что компания не будет возмещать никакие убытки или ущерб, каким бы образом они ни были вызваны.",
        },
        privacyPolicyChanges: {
          h: "9. Изменения в Политике конфиденциальности",
          p1: "9.1. Мы оставляем за собой право в любое время изменять настоящую Политику конфиденциальности. Все изменения вступают в силу немедленно после их публикации в приложении.",
          p2: "9.2. Продолжение использования приложения после внесения изменений означает ваше согласие с этими изменениями.",
        },
        terminationOfUse: {
          h: "10. Прекращение использования",
          p1: "10.1. Мы оставляем за собой право прекратить или приостановить ваш доступ к приложению в любое время без предварительного уведомления.",
          p2: "10.2. Вы можете прекратить использование приложения в любое время, удалив свой аккаунт.",
        },
        contactInfo: {
          h: "11. Контактная информация",
          p1: "Если у вас есть вопросы или замечания относительно этих Условий, пожалуйста, свяжитесь с нами по адресу no-reply@foundy.world.",
        },
        info: "Настоящие Условия вступают в силу с момента их публикации и являются действующими до их отмены или изменения.",
      },
      terms: {
        title: "Условия использования веб-приложения Foundy (Фаунди)",
        version: "Версия 1.1",
        updateDate: "Дата последнего обновления: 10 сентября 2024 г.",
        introduction: {
          h: "1. Введение",
          p1: " Добро пожаловать в Foundy (Фаунди)! Мы рады приветствовать вас на нашей платформе, которая предназначена для облегчения поиска пропавших и найденных вещей, животных и людей. Настоящие Условия использования (далее - «Условия») регулируют использование вами нашего веб-приложения. Просим вас внимательно ознакомиться с этими Условиями перед началом использования приложения. Зарегистрировавшись или используя приложение, вы соглашаетесь с этими Условиями и подтверждаете своё согласие соблюдать все установленные правила и нормы.",
          p2: "Наше приложение создано с целью помочь пользователям находить пропавшие вещи и домашних животных, а также восстанавливать контакт с близкими и друзьями. В случае с людьми, приложение может использоваться для поиска официально пропавших лиц, зарегистрированных в соответствующих базах данных, или для восстановления контакта с людьми, с которыми пользователи утратили связь по взаимному согласию. Мы строго запрещаем использование нашего приложения для целей сталкинга, мошенничества или любых иных незаконных действий.",
          p3: "Пользователи обязаны предоставлять точную и актуальную информацию при регистрации и размещении объявлений. Мы стремимся обеспечить безопасность и доверие в нашем сообществе, поэтому все пользователи должны соблюдать требования законодательства о защите персональных данных. Пользователи, нарушающие Условия использования, могут быть подвергнуты блокировке доступа к приложению без предварительного уведомления.",
          p4: "Обратите внимание, что опубликованные на нашем сайте объявления могут носить демонстрационный характер и не обязательно отражают реальные предложения или события. Мы оставляем за собой право публиковать примеры объявлений для демонстрации функционала сайта и его возможностей. Пользователи должны учитывать, что такие объявления могут быть показаны исключительно в демонстрационных целях и не предполагают актуальности или достоверности.",
          p5: "Использование нашего приложения подразумевает ваше согласие с тем, что вы понимаете и принимаете риски, связанные с публичным размещением информации. Мы не несем ответственности за достоверность и содержание объявлений, размещенных пользователями, однако мы оставляем за собой право удалять любой контент.",
          p6: "Зарегистрировавшись или продолжив использование приложения после внесения изменений в настоящие Условия, вы подтверждаете свое согласие с этими изменениями. Мы рекомендуем периодически просматривать Условия использования для ознакомления с актуальными правилами и политиками. Благодарим вас за доверие и желаем успешного использования Foundy (Фаунди) для достижения ваших целей.",
        },
        general: {
          h: "2. Общие положения",
          p1: "2.1. Эти Условия являются юридически обязывающим соглашением между вами (далее - «Пользователь») и Foundy (Фаунди), действующим через самозанятого предпринимателя, зарегистрированного в налоговых органах Российской Федерации под ИНН 780729468799 (далее - «Компания», «мы», «нас» или «наш»).",
          p2: "2.2. Используя наше приложение, вы подтверждаете, что достигли возраста, необходимого для заключения юридически обязывающих договоров, и имеете право и полномочия на это.",
          p3: " 2.3. Мы оставляем за собой право изменять эти Условия в любое время. Все изменения вступают в силу немедленно после их публикации в приложении.",
          p4: "2.4. Приложение Foundy (Фаунди) предназначено для публикации объявлений о пропавших и найденных людях, вещах и животных. В случае с людьми оно может использоваться для поиска официально пропавших лиц или для поиска друзей и близких. Приложение не должно использоваться для сталкинга, мошенничества или других незаконных действий. Все пользователи, размещающие объявления, несут полную ответственность за содержание своих объявлений и подтверждают, что они получили все необходимые согласия от лиц, данные которых они публикуют.",
        },
        registartionAndAccount: {
          h: "3. Регистрация и аккаунт",
          p1: "3.1. Для доступа к некоторым функциям приложения вам необходимо зарегистрироваться, предоставив достоверные и полные данные. Вы несете полную ответственность за сохранность конфиденциальности своего аккаунта и пароля.",
          p2: " 3.2. Вы соглашаетесь немедленно уведомлять нас о любом несанкционированном использовании вашего аккаунта.",
          p3: "3.3. Пользователь обязан предоставить актуальную контактную информацию и поддерживать её в актуальном состоянии.",
        },
        userResponsibility: {
          h: "4. Ответственность пользователя",
          p1: "4.1. Пользователь несет полную ответственность за весь контент, который он размещает, загружает или передает через наше приложение.",
          p2: "4.2. Пользователь обязуется не размещать и не передавать контент, который является незаконным, вредоносным, оскорбительным, клеветническим, нарушающим права других лиц, или который может привести к гражданской или уголовной ответственности.",
          p3: "4.3. Пользователь обязуется не использовать приложение для целей, связанных с мошенничеством, воровством, преследованием (сталкингом) или другими незаконными действиями.",
          p4: "4.4. Пользователь соглашается с тем, что все действия, выполняемые через его аккаунт, считаются выполненными самим пользователем.",
        },
        contentModerationAndSafety: {
          h: "5. Модерация контента и безопасность",
          p1: "5.1. Мы оставляем за собой право модерации и удаления контента, который, по нашему мнению, нарушает эти Условия или является незаконным.",
          p2: "5.2. Мы не несем ответственности за контент, размещенный пользователями, но оставляем за собой право сотрудничать с правоохранительными органами в случае подозрения на незаконную деятельность.",
          p3: "5.3. Мы призываем пользователей проявлять осторожность и следовать общим правилам безопасности при общении и взаимодействии с другими пользователями.",
        },
        paidServices: {
          h: "6. Платные услуги",
          p1: " 6.1. Вознаграждение нашедшему: При публикации объявления в разделе потерянных вещей пользователи могут назначить сумму вознаграждения для нашедшего. Пользователь обязуется оплатить 7% от назначенной суммы вознаграждения через платежную систему, доступную на платформе. Указание суммы вознаграждения может повысить видимость объявления и заинтересованность других пользователей благодаря сортировке по размеру вознаграждения.",
          p2: "6.2. Выбор услуги: при создании объявления пользователь указывает сумму и валюту вознаграждения для нашедшего.",
          p3: "6.3. Оплата через платежную систему: После выбора услуги пользователю будет предложено оплатить её через интегрированную платежную систему. Пользователь должен следовать инструкциям на экране для завершения оплаты.",
          p4: "6.4. Подтверждение оплаты: После успешного завершения оплаты электронный чек будет автоматически отправлен пользователю.",
          p5: "6.5. Возмещение и ответственность: Компания не несет ответственности за неудачные попытки оплаты, задержки в обработке платежей, а также за любые технические сбои, возникающие на стороне платежных систем. Пользователь несет полную ответственность за точность предоставляемых данных и правильность выполнения платежных операций.",
          p6: "6.6. Все транзакции и выплаты вознаграждений осуществляются непосредственно между пользователями. Компания не контролирует и не регулирует процесс выплаты вознаграждений. Мы не несем ответственности за то, выплачено ли вознаграждение и как это происходит.",
          p7: "6.7. Компания не предоставляет никаких гарантий относительно успешности выплат вознаграждений. Пользователи обязаны самостоятельно урегулировать вопросы, касающиеся выплаты вознаграждений, без привлечения Компании.",
          p8: "6.8. Компания оставляет за собой право изменять условия предоставления платных услуг и процесс оплаты.",
        },
        refund: {
          h: "7. Возврат средств",
          p1: "7.1. Все платежи, произведенные за платные услуги, не подлежат возврату, за исключением случаев, прямо предусмотренных законодательством Российской Федерации.",
          p2: "7.2. В случае ошибочного платежа пользователь должен незамедлительно обратиться в службу поддержки с запросом на возврат средств. Запрос будет рассмотрен в течение 30 рабочих дней с момента получения всех необходимых доказательств ошибки.",
          p3: "7.3. Компания оставляет за собой право отказать в возврате средств, если будет установлено, что платеж был произведен корректно и услуги были оказаны в соответствии с условиями договора.",
          p4: "7.4. Средства не подлежат возврату, если запрос на возврат был подан более чем через 30 дней с момента совершения ошибочного платежа.",
          p5: "7.5. При возврате средств сумма будет уменьшена на сумму комиссии, удержанную платежной системой при оплате платных услуг. Компания не возмещает эти комиссии, так как платежная система является посредником и удерживает свои проценты.",
        },
        limitationOfLiability: {
          h: "8. Ограничение ответственности",
          p1: "8.1. Приложение предоставляется «как есть», и мы не даем никаких гарантий в отношении его работоспособности, точности данных и безопасности.",
          p2: "8.2. Мы не несем ответственности за любой ущерб, прямой или косвенный, возникший в результате использования или невозможности использования приложения.",
          p3: "8.3. Пользователь соглашается, что компания не несет ответственности за любые убытки или ущерб, причиненные пользователю или третьим лицам в результате использования приложения, включая, но не ограничиваясь, упущенную выгоду, потерю данных или любые другие убытки.",
          p4: "8.4. В максимальной степени, разрешенной применимым законодательством, мы не несем никакой ответственности перед вами за любые претензии, связанные с использованием приложения.",
          p5: "8.5. Пользователь соглашается, что компания не будет возмещать никакие убытки или ущерб, каким бы образом они ни были вызваны.",
        },
        disclaimerAgreement: {
          h: "9. Соглашение об ограничении ответственности",
          p1: "9.1. Зарегистрировавшись в нашем приложении или используя его, пользователь соглашается с тем, что вся ответственность за размещение и содержание информации лежит на нем.",
          p2: "9.2. Компания не несет ответственности за любые действия, связанные с использованием информации, размещенной пользователями, включая, но не ограничиваясь, действия мошенников, воров и преследователей.",
        },
        privacyAndDataProtection: {
          h: "10. Конфиденциальность и защита данных",
          p1: "10.1. Мы соблюдаем законодательство о защите персональных данных и обязуемся защищать вашу личную информацию.",
          p2: "10.2. Используя наше приложение, вы соглашаетесь с условиями нашей Политики конфиденциальности, которая является неотъемлемой частью этих Условий.",
        },
        terminationOfUse: {
          h: "11. Прекращение использования",
          p1: "11.1. Мы оставляем за собой право прекратить или приостановить ваш доступ к приложению в любое время без предварительного уведомления, если вы нарушаете эти Условия.",
          p2: "11.2. Вы можете прекратить использование приложения в любое время, удалив свой аккаунт.",
        },
        lawAndJurisdiction: {
          h: "12. Применимое право и юрисдикция",
          p1: "12.1. Эти Условия регулируются и толкуются в соответствии с законодательством Российской Федерации.",
          p2: "12.2. Все споры, возникающие в связи с настоящими Условиями, подлежат разрешению в судах по месту пребывания представителя компании.",
        },
        contactInfo: {
          h: "13. Контактная информация",
          p1: "Если у вас есть вопросы или замечания относительно этих Условий, пожалуйста, свяжитесь с нами по адресу no-reply@foundy.world.",
        },
        info: "Настоящие Условия вступают в силу с момента их публикации и являются действующими до их отмены или изменения.",
      },
    },
  },
  lng: "ru",
  fallbackLng: "en",
  ns: ["home", "searchPage", "header", "announcement", "constants"],
  defaultNS: "home",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
