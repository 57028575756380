import React from "react";
import { Link } from "react-router-dom";
import styles from "./MobileSubBar.module.scss";
import { ReactComponent as BackIcon } from "../../../../../../assets/svg/controlButtons/arrowBig.svg";

const MobileSubBar = ({ items, onClose, closeMobileBar }) => {
  return (
    <div className={styles.mobileSubBar}>
      <div className={`${styles.actionSheet} elevation4`}>
        <button onClick={onClose} className={styles.backButton}>
          <BackIcon />
        </button>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {item.separator && <div className={styles.separator}></div>}
            {item.to ? (
              <Link
                className={styles.actionSheetItem}
                to={item.to}
                onClick={(e) => {
                  if (item.onClick) item.onClick(e);
                  onClose();
                  closeMobileBar();
                }}
              >
                {item.icon && (
                  <img className={styles.icon} src={item.icon} alt="Icon" />
                )}
                {item.text}
              </Link>
            ) : (
              <div
                className={styles.actionSheetItem}
                onClick={(e) => {
                  if (item.onClick) item.onClick(e);
                }}
              >
                {item.icon && (
                  <img className={styles.icon} src={item.icon} alt="Icon" />
                )}
                {item.text}
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default MobileSubBar;
