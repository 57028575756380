import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserAvatar from "../../../UserAvatar";
import styles from "./MobileBar.module.scss";
import { useNavigate, Link } from "react-router-dom";
import { clearUser } from "../../../../redux/slices/authSlice";
import AuthService from "../../../../services/AuthService";
import PreferencesIcon from "../../../../assets/svg/icons/preferences.svg";
import LogOutIcon from "../../../../assets/svg/icons/logOut.svg";
import MessageIcon from "../../../../assets/svg/icons/messageSmall.svg";
import { useTranslation } from "react-i18next";
import MobileSubBar from "./components/MobileSubBar";

const MobileBar = ({ onClose }) => {
  const { t } = useTranslation("header");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.auth.user);
  const [showSubBar, setShowSubBar] = useState(false);
  const [subBarItems, setSubBarItems] = useState([]);

  const handleLogout = () => {
    AuthService.logout(dispatch);
    dispatch(clearUser());
    onClose();
  };

  const handleActionSheetItemClick = (action) => {
    switch (action) {
      case "logout":
        handleLogout();
        break;
      case "messages":
        navigate("/chat");
        onClose();
        break;
      case "settings":
        setSubBarItems([
          { text: t("profile"), to: "/editProfile?tab=profile" },
          { text: t("security"), to: "/editProfile?tab=security" },
        ]);
        setShowSubBar(true);
        break;
      case "adminPanel":
        setSubBarItems([
          { text: t("roleManager"), to: "/adminPanel?tab=roles" },
          { text: t("moderation"), to: "/adminPanel?tab=moderation" },
        ]);
        setShowSubBar(true);
        break;
      default:
    }
  };

  const canViewAdminPanel =
    currentUser?.roles?.includes("vanillaalbino") ||
    currentUser?.roles?.includes("administrator") ||
    currentUser?.roles?.includes("moderator");

  const actionSheetItems = [
    {
      text: t("messages"),
      icon: MessageIcon,
      onClick: () => handleActionSheetItemClick("messages"),
    },
    {
      text: t("profileSettings"),
      icon: PreferencesIcon,
      onClick: () => handleActionSheetItemClick("settings"),
    },
    ...(canViewAdminPanel
      ? [
          {
            text: t("adminPanel"),
            onClick: () => handleActionSheetItemClick("adminPanel"),
          },
        ]
      : []),
    {
      text: t("logOut"),
      onClick: () => handleActionSheetItemClick("logout"),
      icon: LogOutIcon,
      separator: true,
    },
  ];

  const handleCloseSubBar = () => {
    setShowSubBar(false);
  };

  return (
    <div className={styles.wrapper}>
      {showSubBar && (
        <MobileSubBar
          items={subBarItems}
          onClose={handleCloseSubBar}
          closeMobileBar={onClose}
        />
      )}
      <div className={styles.mobileBar}>
        <Link
          to={`/user/${currentUser?.id}?branch=LOST`}
          className={styles.userInfoWrapper}
          onClick={onClose}
        >
          <UserAvatar src={currentUser?.userpic} size="40px" />
        </Link>
        <div className={`${styles.actionSheet} elevation4`}>
          {actionSheetItems.map((item, index) => (
            <React.Fragment key={index}>
              {item.separator && <div className={styles.separator}></div>}
              <div
                className={styles.actionSheetItem}
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.onClick) item.onClick(e);
                }}
              >
                {item.icon && (
                  <img className={styles.icon} src={item.icon} alt="Icon" />
                )}
                {item.text}
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileBar;
