import i18n from "./i18n";

export const setupLanguage = () => {
  const userLanguage = navigator.language || navigator.userLanguage;
  let appLanguage = "en";
  if (userLanguage.startsWith("ru")) {
    appLanguage = "ru";
  }
  i18n.changeLanguage(appLanguage);
  let defaultCurrency = "$";
  if (appLanguage === "ru") {
    defaultCurrency = "₽";
  }

  return {
    language: appLanguage,
    currency: defaultCurrency,
  };
};
