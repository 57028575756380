import { createSlice } from "@reduxjs/toolkit";
export const filterSlice = createSlice({
  name: "filter",
  initialState: {
    branch: "FOUND",
    title: "",
    location: "",
    category: null,
    searchInitiated: false,
  },
  reducers: {
    setBranch: (state, action) => {
      state.branch = action.payload;
    },
    setTitle: (state, action) => {
      state.title = action.payload;
    },
    setLocation: (state, action) => {
      state.location = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setSearchButtonClicked: (state, action) => {
      state.searchInitiated = !state.searchInitiated;
    },
    resetFilters: (state) => {
      state.branch = "FOUND";
      state.title = "";
      state.location = "";
      state.category = null;
    },
  },
});

export const {
  setBranch,
  setTitle,
  setLocation,
  setCategory,
  setSearchButtonClicked,
  resetFilters,
} = filterSlice.actions;

export const selectFilter = (state) => state.filter;
export default filterSlice.reducer;
