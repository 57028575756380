import api from "../http";

class ChatService {
  async fetchMessagesByParticipantIds(userId, interlocutor) {
    try {
      const response = await api.get(`/chat/messages`, {
        params: {
          userId: userId,
          interlocutor: interlocutor,
        },
      });
      if (response.status !== 200) {
        throw new Error("Ошибка при выполнении запроса");
      }
      const responseData = response.data;
      return responseData;
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      throw error;
    }
  }

  async fetchUnreadMessagesCount(userId) {
    try {
      const response = await api.get(`/chat/unreadMessagesCount/${userId}`);
      if (response.status === 200) {
        return response.data.unreadMessagesCount;
      } else {
        throw new Error("Ошибка получения количества непрочитанных сообщений.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении запроса на сервер:", error);
      throw error;
    }
  }

  async fetchChatsByUserId(userId) {
    try {
      const response = await api.get(`/chat/chats`, {
        params: {
          userId: userId,
        },
      });
      if (response.status !== 200) {
        throw new Error("Ошибка при выполнении запроса");
      }
      const responseData = response.data;
      return responseData;
    } catch (error) {
      console.error("Error fetching chat messages:", error);
      throw error;
    }
  }
}

const chatServiceInstance = new ChatService();
export default chatServiceInstance;
