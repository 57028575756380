import { createSlice } from "@reduxjs/toolkit";

const sortSlice = createSlice({
  name: "sort",
  initialState: {
    sortByPriceDescending: false,
  },
  reducers: {
    toggleSortByPriceDescending(state) {
      state.sortByPriceDescending = !state.sortByPriceDescending;
    },
    resetSort(state) {
      state.sortByPriceDescending = false;
    },
  },
});

export const { toggleSortByPriceDescending, resetSort } = sortSlice.actions;
export default sortSlice.reducer;
