import axios from "axios";
import { clearUser } from "../redux/slices/authSlice";
import api from "../http";

const API_URL = process.env.REACT_APP_API_URL + "/api";

class UserService {
  async getProfile() {
    try {
      const response = await api.get("/user/profile");
      const userProfile = response.data;
      return userProfile;
    } catch (error) {
      console.error("Error fetching user profile:", error);
      throw error;
    }
  }

  async editProfile(profileData) {
    try {
      const response = await api.post(`/user/edit-profile`, profileData);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(
          `Failed to edit profile with status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error editing user profile:", error);
      throw error;
    }
  }

  async deleteUserById(userId, dispatch) {
    try {
      if (userId === 777) {
        throw new Error("User with id 777 cannot be deleted.");
      }
      const response = await api.post(`/user/delete-user/${userId}`);
      if (response.status === 200) {
        localStorage.removeItem("currentUser");
        localStorage.removeItem("isAuth");
        if (dispatch) {
          dispatch(clearUser());
        }
        return response.data;
      } else {
        throw new Error(
          `Failed to delete user with status: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      throw error;
    }
  }

  async getUserById(userId) {
    if (!userId || isNaN(Number(userId))) {
      console.error("Invalid user ID provided:", userId);
      throw new Error("Invalid user ID provided");
    }
    try {
      const response = await axios.get(`${API_URL}/user/${userId}/profile`);
      return response.data;
    } catch (error) {
      console.error("Error fetching other user profile:", error);
      throw error;
    }
  }

  async changePassword(passwordData) {
    try {
      const response = await api.post(`/user/change-password`, {
        currentPassword: passwordData.currentPassword,
        newPassword: passwordData.newPassword,
      });
      return response.data;
    } catch (error) {
      console.error(
        "Error during password change:",
        error.response ? error.response.data : error
      );
      throw error.response.data;
    }
  }

  async requestPasswordReset(email) {
    try {
      const response = await axios.post(
        `${API_URL}/user/request-reset-password`,
        {
          email,
        }
      );
      if (response.status !== 200) {
        throw new Error(
          response.data.message ||
            "Произошла ошибка при запросе на сброс пароля"
        );
      }
      return response.data;
    } catch (error) {
      console.error("Ошибка при выполнении запроса на сброс пароля:", error);
      throw error;
    }
  }

  async PasswordReset(token, newPassword) {
    try {
      const response = await axios.post(`${API_URL}/user/reset-password`, {
        token,
        newPassword,
      });
      if (response.status !== 200) {
        throw new Error("Ошибка сброса пароля");
      }
      return response.data;
    } catch (error) {
      console.error("Ошибка при сбросе пароля:", error);
      throw error;
    }
  }
}

const userServiceInstance = new UserService();
export default userServiceInstance;
