import axios from "axios";
import qs from "qs";
import api from "../http";
const API_URL = process.env.REACT_APP_API_URL + "/api";

class AnnouncementService {
  async uploadMedia(file) {
    const formData = new FormData();
    formData.append("media", file);
    try {
      const response = await api.post(
        `/image/upload-announcement-media`,
        formData
      );
      if (response.status === 200) {
        const responseData = response.data;
        const mediaUrls = responseData.mediaUrls;
        const previewUrls = responseData.previewUrls;
        return { mediaUrls, previewUrls };
      } else {
        console.error(
          "Failed to upload media, server responded with status:",
          response.status
        );
        return null;
      }
    } catch (error) {
      console.error("Error uploading media:", error);
      return null;
    }
  }

  async createAnnouncement(data) {
    try {
      const response = await api.post(
        `/announcement/create-announcement`,
        data
      );
      if (response.status === 200 || response.status === 201) {
        console.log("Raw server response:", response.data);
        const { message, announcement } = response.data;
        const { success, paymentLink } = announcement;
        console.log("Processed response:", {
          success,
          paymentLink,
          message,
          announcement,
        });
        return { success, paymentLink, message, announcement };
      } else {
        throw new Error(
          `Failed to create announcement with status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error creating announcement:", error);
      return {
        success: false,
        error: error.response ? error.response.data : error,
      };
    }
  }

  async fetchAnnouncements({
    category,
    branch,
    location,
    title,
    userId,
    sortBy,
    order,
    limit = 20,
    offset = 0,
  }) {
    const params = {
      category,
      branch,
      location: location,
      title: title,
      userId,
      sortBy,
      order,
      limit,
      offset,
    };
    const queryString = qs.stringify(params, { arrayFormat: "repeat" });
    try {
      const response = await axios.get(
        `${API_URL}/announcement?${queryString}`
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.status !== 200) {
        throw new Error(
          `Network response was not ok: ${error.response.status}`
        );
      } else {
        throw new Error("An error occurred while fetching the announcements");
      }
    }
  }

  async fetchAnnouncementById(id) {
    try {
      const response = await axios.get(`${API_URL}/announcement/${id}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status !== 200) {
        throw new Error(
          `Network response was not ok: ${error.response.status}`
        );
      } else {
        throw new Error("An error occurred while fetching the announcement");
      }
    }
  }

  async deleteAnnouncementById(userId, announcementId) {
    try {
      const response = await api.delete(
        `/announcement/delete-announcement/${userId}/${announcementId}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(
          `Failed to delete announcement with status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error deleting announcement:", error);
      throw error;
    }
  }

  async updateAnnouncement(userId, announcementId, data) {
    try {
      const response = await api.patch(
        `/announcement/update-announcement/${userId}/${announcementId}`,
        data
      );
      if (response.status === 200) {
        return { success: true, message: "Announcement updated successfully" };
      } else {
        throw new Error(
          `Failed to update announcement with status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Error updating announcement:", error);
      throw error;
    }
  }
}

const announcementServiceInstance = new AnnouncementService();
export default announcementServiceInstance;
