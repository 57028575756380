import axios from "axios";
import AuthService from "../services/AuthService";
import { jwtDecode } from "jwt-decode";

const BASE_URL = process.env.REACT_APP_API_URL;
const API_URL = `${BASE_URL}/api`;

const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

export function redirectToHome() {
  window.location.href = "/";
}

export function isTokenExpired() {
  try {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken || typeof accessToken !== "string") {
      console.error("Access token is missing or invalid:", accessToken);
      return true;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Math.floor(Date.now() / 1000);
    const isExpired = decoded.exp < currentTime;
    if (isExpired) {
    } else {
    }
    return isExpired;
  } catch (error) {
    console.error("Error decoding access token:", error);
    return true;
  }
}

api.interceptors.request.use(
  async (config) => {
    let accessToken = localStorage.getItem("accessToken");
    if (accessToken && !isTokenExpired(accessToken)) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    } else {
      if (!accessToken) {
      } else if (isTokenExpired(accessToken)) {
        try {
          const newAccessToken = await AuthService.refresh();
          localStorage.setItem("accessToken", newAccessToken.accessToken);
          accessToken = newAccessToken.accessToken;
          config.headers.Authorization = `Bearer ${newAccessToken.accessToken}`;
        } catch (refreshError) {
          console.error("Failed to refresh token:", refreshError);
          throw new Error("Failed to refresh token");
        }
      } else {
        throw new Error("Unknown issue with accessToken");
      }
    }
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const newAccessToken = await AuthService.refresh();
        localStorage.setItem("accessToken", newAccessToken.accessToken);
        api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${newAccessToken.accessToken}`;
        originalRequest.headers[
          "Authorization"
        ] = `Bearer ${newAccessToken.accessToken}`;
        return api(originalRequest);
      } catch (refreshError) {
        console.error("Failed to refresh token after 401 error:", refreshError);
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
