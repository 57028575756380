import React, { useState } from "react";
import { ReactComponent as UserpicDefaultImage } from "../../assets/svg/userpicDefaultImage.svg";

function UserAvatar({ src, size, minSize }) {
  const [imgError, setImgError] = useState(false);

  const getProperImageUrl = (src) => {
    if (!src) return null;
    const isDataURL = /^data:image\/.*;base64,/.test(src);
    if (src.startsWith("http://") || src.startsWith("https://") || isDataURL) {
      return src;
    }
    const adjustedPath = src.startsWith("/") ? src.substring(1) : src;
    return `${process.env.REACT_APP_API_URL}/${adjustedPath}`;
  };

  const properSrc = getProperImageUrl(src);

  return (
    <div
      className="userAvatar"
      style={{
        minWidth: minSize,
        minHeight: minSize,
        width: size,
        height: size,
        borderRadius: "50%",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {properSrc && !imgError ? (
        <img
          src={properSrc}
          alt="User Avatar"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
          onError={() => setImgError(true)}
        />
      ) : (
        <UserpicDefaultImage
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
    </div>
  );
}

export default UserAvatar;
