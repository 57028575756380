import React, { useState, useEffect } from "react";
import styles from "./MainHeader.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setShowAuthForm } from "../../../redux/slices/uiSlice";
import ProfileButtons from "../components/ProfileButtons";
import Language from "../components/Language";
import UnreadMessagesCounter from "../components/UnreadMessagesCounter";
import { ReactComponent as MessageIcon } from "../../../assets/svg/icons/message.svg";
import { ReactComponent as GamburgerIcon } from "../../../assets/svg/controlButtons/gamburger.svg";
import { ReactComponent as CloseIcon } from "../../../assets/svg/controlButtons/closeBig.svg";
import { ReactComponent as Logo } from "../../../assets/svg/logo.svg";

import MobileBar from "../components/MobileBar";

function MainHeader() {
  const { t } = useTranslation("header");
  const [hasShadow, setHasShadow] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpenAuthForm = () => {
    dispatch(setShowAuthForm(true));
  };

  const handleAdvertisementButtonClick = () => {
    if (isAuth) {
      navigate("/newAnnouncement");
    } else {
      handleOpenAuthForm();
    }
  };

  const toggleHeader = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleScroll = () => {
      const exceedsScrollThreshold = window.pageYOffset > 632;
      setHasShadow(exceedsScrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleCloseMobileBar = () => {
    setIsExpanded(false);
  };

  return (
    <div
      className={`${styles.mainHeader} ${styles.fixed} ${
        hasShadow ? "elevation3" : ""
      } ${isExpanded ? styles.expanded : ""}`}
    >
      <div className={styles.content}>
        <Link to="/">
          <button className={styles.logo}>
            <Logo />
          </button>
        </Link>
        <div className={styles.buttonsContainer}>
          <div className={styles.leftButtons}>
            <Language />
            {isAuth && (
              <Link to="/chat">
                <button className={styles.chatButton}>
                  <UnreadMessagesCounter />
                  <MessageIcon />
                </button>
              </Link>
            )}
          </div>

          {isAuth ? (
            <>
              <ProfileButtons />
              <button
                className={styles.gamburgerCloseButton}
                onClick={toggleHeader}
              >
                {isExpanded ? <CloseIcon /> : <GamburgerIcon />}
              </button>
            </>
          ) : (
            <button
              onClick={handleOpenAuthForm}
              className={`buttonText ${styles.loginSignupBtn}`}
            >
              {t("loginSignup")}
            </button>
          )}

          <button
            onClick={handleAdvertisementButtonClick}
            className={`buttonSmall ${styles.advertisementBtn} buttonTertiary`}
          >
            {t("postAnnouncement")}
          </button>
        </div>
      </div>
      {isExpanded && <MobileBar onClose={handleCloseMobileBar} />}
    </div>
  );
}

export default MainHeader;
