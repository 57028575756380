import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { clearUser } from "../../../../redux/slices/authSlice";
import AuthService from "../../../../services/AuthService";
import styles from "./ProfileButtons.module.scss";
import ActionSheet from "../../../Forms/ActionSheet";
import { ReactComponent as ArrowSmallIcon } from "../../../../assets/svg/controlButtons/arrowSmall.svg";
import UserAvatar from "../../../UserAvatar";
import PreferencesIcon from "../../../../assets/svg/icons/preferences.svg";
import LogOutIcon from "../../../../assets/svg/icons/logOut.svg";
import { useTranslation } from "react-i18next";

function ProfileButtons() {
  const { t } = useTranslation("header");
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const [isActionSheetVisible, setIsActionSheetVisible] = useState(false);

  const handleLogout = () => {
    AuthService.logout(dispatch);
    dispatch(clearUser());
    setIsActionSheetVisible(false);
  };

  const handleActionSheetItemClick = (action) => {
    setIsActionSheetVisible(false);

    switch (action) {
      case "logout":
        handleLogout();
        break;
      case "settings":
        navigate("/editProfile?tab=profile");
        break;
      case "adminPanel":
        navigate("/adminPanel?tab=roles");
        break;
      default:
    }
  };

  const canViewAdminPanel =
    currentUser?.roles?.includes("vanillaalbino") ||
    currentUser?.roles?.includes("administrator") ||
    currentUser?.roles?.includes("moderator");

  const actionSheetItems = [
    {
      text: t("profileSettings"),
      icon: PreferencesIcon,
      onClick: () => handleActionSheetItemClick("settings"),
    },
    ...(canViewAdminPanel
      ? [
          {
            text: t("adminPanel"),
            onClick: () => handleActionSheetItemClick("adminPanel"),
          },
        ]
      : []),
    {
      text: t("logOut"),
      onClick: () => handleActionSheetItemClick("logout"),
      icon: LogOutIcon,
      separator: true,
    },
  ];

  return (
    <div
      className={styles.profileButtonsContainer}
      onMouseLeave={() => setIsActionSheetVisible(false)}
    >
      <div
        className={styles.profileButtons}
        onMouseEnter={() => setIsActionSheetVisible(true)}
      >
        <Link
          to={`/user/${currentUser?.id}?branch=LOST`}
          className={styles.buttonContent}
        >
          <div className={styles.userpicWrapper}>
            <UserAvatar src={currentUser?.userpic} size="32px" />
          </div>
          <div className={styles.arrowWrapper}>
            <ArrowSmallIcon
              className={`${styles.arrow} ${
                isActionSheetVisible ? styles.open : ""
              }`}
            />
          </div>
        </Link>
      </div>

      {isActionSheetVisible && (
        <div
          className={styles.actionSheetWrapper}
          onMouseEnter={() => setIsActionSheetVisible(true)}
          onMouseLeave={() => setIsActionSheetVisible(false)}
        >
          <ActionSheet
            items={actionSheetItems}
            onClose={() => setIsActionSheetVisible(false)}
            align="left"
          />
        </div>
      )}
    </div>
  );
}

export default ProfileButtons;
