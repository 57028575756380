import React, { useEffect } from "react";
import styles from "./Snackbar.module.scss";
import { ReactComponent as CheckmarkIcon } from "../../../assets/svg/icons/checkmark.svg";
import { useDispatch } from "react-redux";
import { setShowSnackbar } from "../../../redux/slices/uiSlice";

const Snackbar = ({ message, isOpen, onClose }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        dispatch(setShowSnackbar(false));
        if (onClose) onClose();
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, dispatch, onClose]);
  if (!isOpen) {
    return null;
  }

  const handleCloseSnackbar = () => {
    dispatch(setShowSnackbar(false));
    if (onClose) onClose();
  };

  return (
    <div
      className={` ${styles.snackbar} elevation4`}
      onClick={handleCloseSnackbar}
    >
      <CheckmarkIcon />
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default Snackbar;
