import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser, clearUser } from "./redux/slices/authSlice";
import AuthService from "./services/AuthService";
import { isTokenExpired } from "./http";
import "./index.scss";
import MainHeader from "./components/Header/MainHeader";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { setupLanguage } from "./localization/localization";
import "./localization/i18n";
import userServiceInstance from "./services/UserService";
import Snackbar from "./components/Forms/Snackbar";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/montserrat/600.css";
import "@fontsource/montserrat/800.css";
const Home = lazy(() => import("./pages/Home"));
const Page404 = lazy(() => import("./pages/Page404"));
const SearchPage = lazy(() => import("./pages/SearchPage"));
const Announcement = lazy(() => import("./pages/Announcement"));
const AuthForm = lazy(() => import("./components/AuthForm"));
const NewAnnouncement = lazy(() => import("./pages/NewAnnouncement"));
const Profile = lazy(() => import("./pages/Profile"));
const EditProfile = lazy(() => import("./pages/EditProfile"));
const Chat = lazy(() => import("./pages/Chat"));
const AdminPanel = lazy(() => import("./pages/AdminPanel"));
const Terms = lazy(() => import("./pages/Info/Terms"));
const Privacy = lazy(() => import("./pages/Info/Privacy"));
const Footer = lazy(() => import("./components/Footer"));

function App() {
  const dispatch = useDispatch();
  const showAuthForm = useSelector((state) => state.ui.showAuthForm);
  const showSnackbar = useSelector((state) => state.ui.showSnackbar);
  const snackbarMessage = useSelector((state) => state.ui.snackbarMessage);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    setupLanguage();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const accessToken = searchParams.get("accessToken");
    if (accessToken) {
      localStorage.setItem("accessToken", accessToken);
      window.location.href = window.location.pathname;
    }
    const initAuth = async () => {
      const accessToken = localStorage.getItem("accessToken");
      if (!accessToken) {
        return;
      }
      const expired = isTokenExpired(accessToken);
      if (expired) {
        try {
          await AuthService.refresh();
        } catch (error) {
          dispatch(clearUser());
          localStorage.removeItem("currentUser");
          localStorage.removeItem("isAuth");
          return;
        }
      }
      const savedUser = localStorage.getItem("currentUser");
      const savedIsAuth = localStorage.getItem("isAuth") === "true";
      if (savedUser && savedIsAuth) {
        dispatch(setUser(JSON.parse(savedUser)));
      } else if (!currentUser) {
        try {
          const profile = await userServiceInstance.getProfile();
          localStorage.setItem("currentUser", JSON.stringify(profile));
          localStorage.setItem("isAuth", "true");
          dispatch(setUser(profile));
        } catch (error) {
          console.error("Error fetching user data:", error);
          dispatch(clearUser());
          localStorage.removeItem("currentUser");
          localStorage.removeItem("isAuth");
        }
      }
    };
    if (!currentUser) {
      initAuth();
    }
  }, [dispatch, isAuth, currentUser]);

  const ProtectedRoute = ({ children, allowedRoles }) => {
    const isAuth = useSelector((state) => state.auth.isAuth);
    const currentUser = useSelector((state) => state.auth.user);
    if (!isAuth) {
      return <Navigate to="/" replace />;
    }
    if (
      allowedRoles &&
      !allowedRoles.some((role) => currentUser?.roles?.includes(role))
    ) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  const ScrollToTop = () => {
    const { pathname } = useLocation();
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
    return null;
  };

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ScrollToTop />
      {showAuthForm && (
        <Suspense>
          <AuthForm />
        </Suspense>
      )}
      {showSnackbar && (
        <Snackbar message={snackbarMessage} isOpen={showSnackbar} />
      )}
      <MainHeader />
      <div className="content">
        <Suspense>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/search" element={<SearchPage />} />
            <Route path="/announcement/:id" element={<Announcement />} />
            <Route path="/user/:userId" element={<Profile />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="*" element={<Page404 />} />
            <Route
              path="/newAnnouncement"
              element={
                <ProtectedRoute>
                  <NewAnnouncement />
                </ProtectedRoute>
              }
            />
            <Route
              path="/editProfile"
              element={
                <ProtectedRoute>
                  <EditProfile />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/chat/:userId"
              element={
                <ProtectedRoute>
                  <Chat />
                </ProtectedRoute>
              }
            />
            <Route
              path="/adminPanel"
              element={
                <ProtectedRoute
                  allowedRoles={["vanillaalbino", "administrator", "moderator"]}
                >
                  <AdminPanel />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </div>
      <Suspense>
        <Footer />
      </Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
