import { configureStore } from "@reduxjs/toolkit";
import filter from "./slices/filterSlice";
import announcements from "./slices/announcementsSlice";
import sort from "./slices/sortSlice";
import authReducer from "./slices/authSlice";
import uiReducer from "./slices/uiSlice";

export const store = configureStore({
  reducer: {
    filter,
    announcements,
    sort,
    auth: authReducer,
    ui: uiReducer,
  },
});
