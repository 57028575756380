import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    showAuthForm: false,
    showSnackbar: false,
    snackbarMessage: "",
  },
  reducers: {
    setShowAuthForm: (state, action) => {
      state.showAuthForm = action.payload;
    },
    setShowSnackbar: (state, action) => {
      state.showSnackbar = action.payload;
    },
    setSnackbarMessage: (state, action) => {
      state.snackbarMessage = action.payload;
    },
    resetSnackbar: (state) => {
      state.showSnackbar = false;
      state.snackbarMessage = "";
    },
  },
});

export const {
  setShowAuthForm,
  setShowSnackbar,
  setSnackbarMessage,
  resetSnackbar,
} = uiSlice.actions;

export default uiSlice.reducer;
