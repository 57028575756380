import { createSlice } from "@reduxjs/toolkit";
export const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuth: false,
    status: "idle",
    error: null,
  },
  reducers: {
    authenticateUser: (state, action) => {
      state.isAuth = action.payload;
    },
    setUser: (state, action) => {
      state.user = {
        email: action.payload.email,
        id: action.payload.id,
        name: action.payload.name,
        userpic: action.payload.userpic,
        roles: action.payload.roles,
      };
      state.isAuth = true;
    },
    clearUser: (state) => {
      state.user = null;
      state.isAuth = false;
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refreshToken");
    },
  },
});

export const { authenticateUser, setUser, clearUser } = authSlice.actions;
export default authSlice.reducer;
