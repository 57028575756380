import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Language.module.scss";
import { ReactComponent as LanguageIcon } from "../../../../assets/svg/icons/language.svg";
import ActionSheet from "../../../Forms/ActionSheet";

function Language() {
  const { i18n } = useTranslation();
  const [isActionSheetVisible, setActionSheetVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  useEffect(() => {
    const handleLanguageChange = (lang) => {
      setSelectedLanguage(lang);
    };
    i18n.on("languageChanged", handleLanguageChange);
    return () => {
      i18n.off("languageChanged", handleLanguageChange);
    };
  }, [i18n]);

  const handleButtonClick = () => {
    setActionSheetVisible(true);
  };

  const handleLanguageSelect = (lang) => {
    i18n.changeLanguage(lang);
    setActionSheetVisible(false);
  };

  const languageOptions = [
    {
      text: "English",
      onClick: () => handleLanguageSelect("en"),
      isActive: selectedLanguage.startsWith("en"),
    },
    {
      text: "Русский",
      onClick: () => handleLanguageSelect("ru"),
      isActive: selectedLanguage.startsWith("ru"),
    },
  ];

  return (
    <div>
      <button
        className={`${styles.languageBtn} headerButton`}
        onClick={handleButtonClick}
      >
        <LanguageIcon />
      </button>{" "}
      <div className={styles.actionSheetWrapper}>
        {isActionSheetVisible && (
          <ActionSheet
            items={languageOptions}
            onClose={() => setActionSheetVisible(false)}
            align="left"
          />
        )}
      </div>
    </div>
  );
}

export default Language;
