import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ChatService from "../../../services/ChatService";
import socket from "../../../socket";

const UnreadMessagesCounter = () => {
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const currentUser = useSelector((state) => state.auth.user);

  useEffect(() => {
    const handleNewMessage = (data) => {
      const { receiverId } = data;

      if (currentUser && parseInt(receiverId) === currentUser.id) {
        setUnreadMessagesCount((prevCount) => prevCount + 1);
      }
    };

    const handleMarkAsRead = (data) => {
      const { receiverId } = data;
      if (currentUser && parseInt(receiverId) === currentUser.id) {
        setUnreadMessagesCount((prevCount) => Math.max(prevCount - 1, 0));
      }
    };

    const fetchUnreadMessagesCount = async () => {
      try {
        if (currentUser) {
          const count = await ChatService.fetchUnreadMessagesCount(
            currentUser.id
          );
          setUnreadMessagesCount(count);
        }
      } catch (error) {
        console.error(
          "Ошибка при получении количества непрочитанных сообщений:",
          error
        );
      }
    };

    fetchUnreadMessagesCount();
    socket.on("newMessage", handleNewMessage);
    socket.on("markAsRead", handleMarkAsRead);
    return () => {
      socket.off("newMessage", handleNewMessage);
      socket.off("markAsRead", handleMarkAsRead);
    };
  }, [currentUser]);

  if (unreadMessagesCount <= 0) {
    return null;
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "4px",
        right: "0",
        padding: "0 6px",
        backgroundColor: "var(--red)",
        color: "white",
        borderRadius: "9px",
        height: "18px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        font: "var(--caption2)",
        fontWeight: "500",
        textAlign: "left",
        direction: "ltr",
        whiteSpace: "nowrap",
      }}
    >
      {unreadMessagesCount}
    </div>
  );
};

export default UnreadMessagesCounter;
