import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { clearUser } from "../redux/slices/authSlice";

const API_URL = process.env.REACT_APP_API_URL + "/api";
const api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

class AuthService {
  async login(email, password) {
    const response = await api.post("/auth/login", { email, password });
    localStorage.setItem("accessToken", response.data.accessToken);
    return { accessToken: response.data.accessToken };
  }

  async registration(email, password) {
    const response = await api.post("/auth/registration", { email, password });
    return response.data;
  }

  async logout(dispatch) {
    await api.post("/auth/logout");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("currentUser");
    if (dispatch) {
      dispatch(clearUser());
    }
  }

  async refresh() {
    try {
      const response = await api.post(
        "/auth/refresh",
        {},
        { withCredentials: true }
      );
      if (response.data.accessToken) {
        localStorage.setItem("accessToken", response.data.accessToken);
        const decodedToken = jwtDecode(response.data.accessToken);
        if (!decodedToken.isActivated) {
          localStorage.removeItem("accessToken");
        }
      } else {
        console.error("Access token is missing from the response");
        throw new Error("Access token missing in the response");
      }
      return response.data;
    } catch (error) {
      console.error("[AuthService] Error during token refresh:", error);
      localStorage.removeItem("accessToken");
      throw error;
    }
  }

  async googleLogin(tokenId) {
    const response = await api.post("/auth/google", { tokenId });
    localStorage.setItem("accessToken", response.data.accessToken);
    return response.data;
  }

  async vkLogin(code) {
    try {
      const response = await api.post("/auth/vk/token", { code });
      return response.data;
    } catch (error) {
      console.error("AuthService error during VK login:", error);
      throw new Error("Error during VK login");
    }
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;
