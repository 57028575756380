import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import announcementService from "../../services/AnnouncementService";

export const fetchAnnouncementsThunk = createAsyncThunk(
  "announcements/fetchAnnouncements",
  async (params, ThunkAPI) => {
    try {
      const data = await announcementService.fetchAnnouncements(params);
      if (data.length === 0) {
        return ThunkAPI.rejectWithValue({ type: "not_found" });
      }
      return data;
    } catch (error) {
      console.error("Error in fetchAnnouncements:", error);
      return ThunkAPI.rejectWithValue({
        type: "general_error",
        message: error.message,
      });
    }
  }
);

export const fetchAnnouncementByIdThunk = createAsyncThunk(
  "announcements/fetchAnnouncementById",
  async (id, ThunkAPI) => {
    try {
      const data = await announcementService.fetchAnnouncementById(id);
      if (data.user) {
      }
      return data;
    } catch (error) {
      return ThunkAPI.rejectWithValue({
        type: "general_error",
        message: error.message,
      });
    }
  }
);

const announcementsSlice = createSlice({
  name: "announcements",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    errorType: null,
    singleAnnouncement: null,
    lastFilterParams: {},
    hasMoreAnnouncements: true,
  },
  reducers: {
    clearAnnouncements: (state) => {
      state.data = [];
      state.status = "idle";
      state.hasMoreAnnouncements = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnnouncementsThunk.pending, (state) => {
        state.status = "loading";
        state.errorType = null;
      })
      .addCase(fetchAnnouncementsThunk.fulfilled, (state, action) => {
        if (action.meta.arg.offset === 0) {
          state.data = action.payload;
        } else {
          state.data = [...state.data, ...action.payload];
        }
        state.hasMoreAnnouncements =
          action.payload.length === action.meta.arg.limit;
        state.status = "succeeded";
      })
      .addCase(fetchAnnouncementsThunk.rejected, (state, action) => {
        console.error("Rejected action error:", action.error);
        state.status = "failed";
        state.error = action.error.message;
        state.errorType = action.payload ? action.payload.type : null;
        if (action.payload && action.payload.type === "not_found") {
          state.hasMoreAnnouncements = false;
        }
      })
      .addCase(fetchAnnouncementByIdThunk.pending, (state) => {
        state.status = "loading";
        state.errorType = null;
      })
      .addCase(fetchAnnouncementByIdThunk.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.singleAnnouncement = action.payload;
      })
      .addCase(fetchAnnouncementByIdThunk.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.errorType = action.payload ? action.payload.type : null;
      });
  },
});

export default announcementsSlice.reducer;
export const selectAnnouncements = (state) => state.announcements.data;
export const selectAnnouncementsStatus = (state) => state.announcements.status;
export const selectSingleAnnouncement = (state) =>
  state.announcements.singleAnnouncement;
export const { clearAnnouncements } = announcementsSlice.actions;
export const selectHasMoreAnnouncements = (state) =>
  state.announcements.hasMoreAnnouncements;
export const selectAnnouncementsErrorType = (state) =>
  state.announcements.errorType;
